<template>
  <div id="container">

    <section id="register">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>塾生情報の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">2</div>
            <p>ご住所の登録</p>
          </div>
          <div class="step_box flex on">
            <div class="num">3</div>
            <p>パスワード設定</p>
          </div>
          <div class="step_box flex on">
            <div class="num">4</div>
            <p>入力内容の確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">5</div>
            <p>入塾金の<br>支払いへ進む</p>
          </div>
        </div>

        <form @submit="nextClicked">

          <div class="cts_box">
            <h2 class="sub_ttl"><span>入力内容の確認</span></h2>
            <p class="reg_lead">
          <span class="blue bold">すべての入力内容に間違いがないことをご確認ください。<br>
            すべての内容を確認の後、画面下の「会員登録を完了する」ボタンを押して会員登録を完了してください。</span><br><br>
              入力内容を修正する場合は、該当する項目をクリックして正しい内容を再度入力してください。<br><br>
              <span class="blue bold">「*」のついている箇所は必須項目です。</span>
            </p>

            <form-error-box v-if="errors"/>

            <div id="class_change" class="reg_set anchor_point">
              <div class="input_set">
                <div class="label">ご希望のクラス・お支払い方法<span>*</span></div>
                <div class="class_check">
                  <div class="select_class">
                    <dl class="flex">
                      <dt>現在ご希望のクラス</dt>
                      <dd class="class">{{ rank_name }}</dd>
                    </dl>
                    <dl class="flex">
                      <dt>お支払い形式</dt>
                      <dd class="payment">{{ rank_payment }}</dd>
                    </dl>
                  </div>

                  <div class="xx_acc_menu">
                    <div class="xx_acc_tab">
                      <span></span>
                      <span style="font-size:0.8rem;">&nbsp;※クラスの変更は入塾後も可能です。</span>
                    </div>
                    <div class="acc_cts">
                      <div class="class_list_box">
                        <div class="class_list flex head">
                          <div class="class">クラス<br class="sp">・お支払い形式</div>
                          <div class="price">お支払い金額(税込)</div>
                          <div class="check">&nbsp;</div>
                          <div class="btn">変更する<br class="sp">場合はクリック</div>
                        </div>

                        <div class="class_list flex check_radio class01">
                          <input type="radio" id="rank_regular_plus_month" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_plus_month" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_plus_month">
                            <div class="class"><span>本科プラス</span>月額払い</div>
                            <div class="price">¥27,500/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class01">
                          <input type="radio" id="rank_regular_plus_year" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_plus_year" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_plus_year">
                            <div class="class"><span>本科プラス</span>年額払い</div>
                            <div class="price">¥22,000/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class02">
                          <input type="radio" id="rank_regular_month" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_month" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_month">
                            <div class="class"><span>本科</span>月額払い</div>
                            <div class="price">¥16,500/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class02">
                          <input type="radio" id="rank_regular_year" name="支払い方法" v-model="new_user.rank"
                                 value="rank_regular_year" v-on:change="rankChange()">
                          <label class="flex" for="rank_regular_year">
                            <div class="class"><span>本科</span>年額払い</div>
                            <div class="price">¥13,500/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio class03">
                          <input type="radio" id="rank_preparatory_month" name="支払い方法" v-model="new_user.rank"
                                 value="rank_preparatory_month" v-on:change="rankChange()">
                          <label class="flex" for="rank_preparatory_month">
                            <div class="class"><span>予科</span>月額払い</div>
                            <div class="price">¥4,950/月</div>
                            <div class="check"><span></span></div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div>
                          </label>
                        </div>
                        <p class="alnR mt10">
                          <a href="#matrix">クラスについての詳細はこちら</a>
                        </p>
                      </div>

                      <div class="class_list_box shikihou" v-bind:class="{no_option: no_option}" v-show="show_shikihou">
                        <div class="shikihou_ttl flex">
                          <h4>東洋経済新報社の「会社四季報ONLINE」の優待プランを追加できます（本科・年額払いのみ）</h4>
                          <p class="tax_caution bold red">※表示はすべて税込価格です</p>
                        </div>

                        <div class="class_list flex check_radio">
                          <input type="radio" id="course04" name="コース" v-model="new_user.shikiho" value=""
                                 v-on:change="shikihoChange()">
                          <label class="flex" for="course04">
                            <div class="shikihou_option min">会社四季報ONLINE の優待プランを選択しない</div>
                            <div class="price pc">&nbsp;</div>
                            <div class="btn"><span></span></div>
                          </label>
                        </div>

                        <div class="class_list flex check_radio">
                          <input type="radio" id="rank_regular_shikiho_premium_year" name="コース"
                                 v-model="new_user.shikiho"
                                 value="rank_regular_shikiho_premium_year"
                                 v-on:change="shikihoChange()">
                          <label class="flex" for="rank_regular_shikiho_premium_year">
                            <div class="shikihou_option">四季報ONLINE プレミアムプランを追加する</div>
                            <div class="price">+¥50,000/年</div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div><!--no_option の時は「選択不可」に変更-->
                          </label>
                        </div>

                        <div class="class_list flex check_radio">
                          <input type="radio" id="rank_regular_shikiho_basic_year" name="コース" v-model="new_user.shikiho"
                                 value="rank_regular_shikiho_basic_year"
                                 v-on:change="shikihoChange()">
                          <label class="flex" for="rank_regular_shikiho_basic_year">
                            <div class="shikihou_option">四季報ONLINE ベーシックプランを追加する</div>
                            <div class="price">＋¥12,000/年</div>
                            <div class="btn"><span></span>
                              <p>選択する</p></div><!--no_option の時は「選択不可」に変更-->
                          </label>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="input_set name">

                <div class="input_set email" v-if="showShikihoAccount()">
                  <div class="label">東洋経済新報社の「会社四季報ONLINE（有償版）」にすでに加入している方はチェックを入れてください。</div>
                  <div class="checkbox">
                    <input id="sns_notice02" type="checkbox" v-model="show_shikiho_account"
                           @change="changeShikihoAccount()">
                    <label for="sns_notice02" class="flex">
                      会社四季報ONLINE（有償版）加入済み
                    </label>
                  </div>
                  <template v-if="show_shikiho_account">
                    <p class="mt5">
                      <span class="bold">年間契約の残存期間がある方は返金を行います。下記の欄に会社四季報ONLINEのログイン用ID（メールアドレス）を入力してください。</span><br>
                      後日、返金のための銀行口座を事務局よりおうかがいします。<br>
                      「会社四季報ONLINE（<span class="red bold">ゲスト・無料会員</span>）」の方は<span class="red bold">返金がないため対象外</span>です。<br>
                      <span class="red bold">「会社四季報ONLINE」のIDをお持ちでない方は何も入力しないでください。</span>IDの確認方法は<a href="https://www.millioneyes.jp/shikiho_online_id/" target="_blank"><span class="bold">こちら。</span></a>
                    </p>
                    <div class="label" style="vertical-align: middle">
                      <img src="/common/images/shikiho_logo.svg" style="width: 100px; vertical-align: middle" alt="">
                      会社四季報ONLINEログイン用ID（メールアドレス）
                    </div>
                    <input type="text" class="email" v-model="new_user.shikiho_account"
                           v-bind:class="{error: validation_errors.shikiho_account}"
                           @input="new_user.shikiho_account = replaceFullToHalf(new_user.shikiho_account)"
                           placeholder="">
                    <div class="shikiho_account_guide">
                      （１）すでにご利用いただいている「会社四季報ONLINE」の年間契約が残っている場合は塾生割引と重複する期間分をご返金いたします。<br>
                      （２）すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）を塾生割引のログイン用メールアドレス に&nbsp;<span class="red bold">紐付け</span>&nbsp;を行います。（紐付けを行うことにより各種の設定や銘柄等の設定を引き継ぐことができます）<br>
                      （３） 紐付けが完了すると、すでにご利用いただいている「会社四季報ONLINE」のログイン用ID（メールアドレス）でログインできるようになります。（紐付けが完了するまではすでにご利用いただいている ログイン用メールアドレス 、塾生割引ログイン用メールアドレス ともにお使いいただけます）<br>
                    </div>
                  </template>
                </div>

                <div class="label">おなまえ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓</div>
                    <input type="text" maxlength="16" v-model="new_user.last_name"
                           v-bind:class="{error: validation_errors.last_name}"
                           @input="new_user.last_name = replaceHalfToFull(new_user.last_name)"
                           placeholder="例：山田">
                    <validation-errors :errors="validation_errors.last_name"
                                       v-if="validation_errors.last_name"></validation-errors>
                  </div>
                  <div class="field">
                    <div class="label">名</div>
                    <input type="text" maxlength="16" v-model="new_user.first_name"
                           v-bind:class="{error: validation_errors.first_name}"
                           @input="new_user.first_name = replaceHalfToFull(new_user.first_name)"
                           placeholder="例：太郎">
                    <validation-errors :errors="validation_errors.first_name"
                                       v-if="validation_errors.first_name"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">フリガナ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓（全角カナ）</div>
                    <input type="text" maxlength="16" v-model="new_user.last_name_kana"
                           @input="new_user.last_name_kana = replaceHiraToKana(new_user.last_name_kana)"
                           v-bind:class="{error: validation_errors.last_name_kana}" placeholder="例：ヤマダ">
                    <validation-errors :errors="validation_errors.last_name_kana"
                                       v-if="validation_errors.last_name_kana"></validation-errors>
                  </div>
                  <div class="field">
                    <div class="label">名（全角カナ）</div>
                    <input type="text" maxlength="16" v-model="new_user.first_name_kana"
                           @input="new_user.first_name_kana = replaceHiraToKana(new_user.first_name_kana)"
                           v-bind:class="{error: validation_errors.first_name_kana}" placeholder="例：タロウ">
                    <validation-errors :errors="validation_errors.first_name_kana"
                                       v-if="validation_errors.first_name_kana"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set">
                <div class="label">性別<span>*</span></div>
                <div class="radio" v-bind:class="{error: validation_errors.gender}">
                  <input id="radio01" type="radio" v-model="new_user.gender" value="male">
                  <label for="radio01">男性</label>

                  <input id="radio02" type="radio" v-model="new_user.gender" value="female">
                  <label for="radio02">女性</label>

                  <input id="radio03" type="radio" v-model="new_user.gender" value="secret">
                  <label for="radio03">その他</label>
                </div>
                <validation-errors :errors="validation_errors.gender"
                                   v-if="validation_errors.gender"></validation-errors>
              </div>

              <div class="input_set birth">
                <div class="label">生年月日<span>*</span></div>
                <p>すべての欄に半角数字でご入力下さい。</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_year"
                           @input="new_user.birthday_year = replaceFullToHalf(new_user.birthday_year)"
                           v-bind:class="{error: validation_errors.birthday_year}" placeholder="年">
                    <validation-errors :errors="validation_errors.birthday_year"
                                       v-if="validation_errors.birthday_year"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_month"
                           @input="new_user.birthday_month = replaceFullToHalf(new_user.birthday_month)"
                           v-bind:class="{error: validation_errors.birthday_month}" placeholder="月">
                    <validation-errors :errors="validation_errors.birthday_month"
                                       v-if="validation_errors.birthday_month"></validation-errors>
                  </div>
                  <div class="field">
                    <input type="text" v-model="new_user.birthday_day"
                           @input="new_user.birthday_day = replaceFullToHalf(new_user.birthday_day)"
                           v-bind:class="{error: validation_errors.birthday_day}" placeholder="日">
                    <validation-errors :errors="validation_errors.birthday_day"
                                       v-if="validation_errors.birthday_day"></validation-errors>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">ユーザーネーム（表示名）<span>*</span></div>
                <p>複眼経済塾WEBサイト上で他の塾生に表示されるお名前です。ログイン後に「マイページ」で変更できます。</p>
                <input type="text" class="nickname" v-bind:class="{error: validation_errors.humhub_username}"
                       v-model="new_user.humhub_username">
                <ValidationErrors :errors="validation_errors.humhub_username"
                                  v-if="validation_errors.humhub_username"></ValidationErrors>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス<span>*</span></div>
                <input type="text" class="email" v-bind:class="{error: validation_errors.email}"
                       v-model="new_user.email">
                <ValidationErrors :errors="validation_errors.email"
                                  v-if="validation_errors.email"></ValidationErrors>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス(確認)<span>*</span></div>
                <input type="text" class="email" v-bind:class="{error: validation_errors.email_confirmation}"
                       v-model="new_user.email_confirmation">
                <ValidationErrors :errors="validation_errors.email_confirmation"
                                  v-if="validation_errors.email_confirmation"></ValidationErrors>
              </div>

              <div class="input_set phone">
                <div class="label">連絡先電話番号<span>*</span></div>
                <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
                <input type="tel" v-model="new_user.phone_number" v-bind:class="{error: validation_errors.phone_number}"
                       @input="new_user.phone_number = replaceFullToHalf(new_user.phone_number)"
                       placeholder="例：09012345678">
                <validation-errors :errors="validation_errors.phone_number"
                                   v-if="validation_errors.phone_number"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">郵便番号<span>*</span></div>
                <p>（半角数字）</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" v-model="new_user.zip" v-bind:class="{error: validation_errors.zip}" name="zip"
                           @input="new_user.zip = replaceFullToHalf(new_user.zip)"
                           placeholder="例：100-1234">
                    <validation-errors :errors="validation_errors.zip" v-if="validation_errors.zip"></validation-errors>
                  </div>
                  <div class="field">
                    <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                  </div>
                </div>
              </div>

              <div class="input_set addr">
                <div class="label">都道府県名<span>*</span></div>
                <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>
                <PrefectureSelect :prefecture="new_user.prefecture" name="prefecture"
                                  v-bind:class="{error: validation_errors.prefecture}"
                                  v-on:changed="prefectureChanged"/>
                <validation-errors :errors="validation_errors.prefecture"
                                   v-if="validation_errors.prefecture"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">市区町村<span>*</span></div>
                <input type="text" v-model="new_user.address1" v-bind:class="{error: validation_errors.address1}"
                       @input="new_user.address1 = replaceHalfToFull(new_user.address1)"
                       name="address1" placeholder="例：新宿区">
                <validation-errors :errors="validation_errors.address1"
                                   v-if="validation_errors.address1"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">地名・番地<span>*</span></div>
                <input type="text" v-model="new_user.address2" v-bind:class="{error: validation_errors.address2}"
                       @input="new_user.address2 = replaceHalfToFull(new_user.address2)"
                       placeholder="例：３丁目１３番地">
                <validation-errors :errors="validation_errors.address2"
                                   v-if="validation_errors.address2"></validation-errors>
              </div>

              <div class="input_set addr">
                <div class="label">建物名・部屋番号<span></span></div>
                <input type="text" v-model="new_user.address3" v-bind:class="{error: validation_errors.address3}"
                       @input="new_user.address3 = replaceHalfToFull(new_user.address3)"
                       placeholder="例：複眼ビル１１１号室">
                <validation-errors :errors="validation_errors.address3"
                                   v-if="validation_errors.address3"></validation-errors>
              </div>

              <div class="input_set">
                <div class="label">パスワード</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_type ? 'text' : 'password'" v-model="new_user.password" class="password"
                         @input="new_user.password = replaceFullToHalf(new_user.password)"
                         placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check01" type="checkbox" v-model="password_type" value="1">
                    <label for="check01">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password"
                                   v-if="validation_errors.password"></validation-errors>
              </div>
              <div class="input_set">
                <div class="label">確認のためにもう一度入力</div>
                <p>（8文字以上の半角英数記号）</p>
                <div class="pswd_box flex">
                  <input v-bind:type="password_confirmation_type ? 'text' : 'password'"
                         @input="new_user.password_confirmation_type = replaceFullToHalf(new_user.password_confirmation_type)"
                         v-model="new_user.password_confirmation" class="password" placeholder="8文字以上">
                  <div class="checkbox">
                    <input id="check02" type="checkbox" v-model="password_confirmation_type" value="1">
                    <label for="check02">入力内容を表示</label>
                  </div>
                </div>
                <validation-errors :errors="validation_errors.password_confirmation"
                                   v-if="validation_errors.password_confirmation"></validation-errors>
              </div>

            </div>

            <div class="input_set">
              <div class="checkbox">
                <input id="check03" type="checkbox" v-model="new_user.enable_primary_address" value="1"
                       v-on:change="enablePrimaryAddressClicked()">
                <label for="check03"
                       class="bold blue">郵便物を別住所で受け取りたい方、海外在住で日本の住所がある方はチェックを入れて、郵便物等の送付先住所指定フォームに別住所を指定してください</label>
                <p class="pl25">※送付先は日本国内に限ります。</p>
              </div>
            </div>

            <div class="reg_set option" v-show="new_user.enable_primary_address">
              <h3 class="min_ttl">郵便物等の送付先住所指定（任意：登録ご住所と同じ場合は入力は不要です）</h3>
              <p class="reg_lead">
                オンラインショップの商品や複眼経済塾からの各種お知らせなど、塾生登録住所以外への送付を希望する場合はご入力ください。<br>塾生登録住所以外で郵便物などを送付する住所のご希望がない場合は入力をスキップしてください。<br><br>
                <span class="blue bold" v-if="false">「*」のついている箇所は必須項目です。</span>
              </p>

              <div class="gray_box">
                <div class="input_set addr">
                  <div class="label">郵便番号</div>
                  <p>（半角数字）</p>
                  <div class="input_fields flex">
                    <div class="field">
                      <input type="text" v-model="new_user.opt_zip" v-bind:class="{error: validation_errors.opt_zip}"
                             @input="new_user.opt_zip = replaceFullToHalf(new_user.opt_zip)"
                             name="opt_zip" placeholder="例：100-1234">
                      <validation-errors :errors="validation_errors.opt_zip"
                                         v-if="validation_errors.opt_zip"></validation-errors>
                    </div>
                    <div class="field">
                      <button type="button" class="ajaxzip3 option" v-on:click="optZipSearch">郵便番号から住所を取得
                      </button>
                    </div>
                  </div>
                </div>

                <div class="input_set addr">
                  <div class="label">都道府県名</div>
                  <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>
                  <PrefectureSelect :prefecture="new_user.opt_prefecture" name="opt_prefecture"
                                    v-bind:class="{error: validation_errors.opt_prefecture}"
                                    v-on:changed="optPrefectureChanged"/>
                  <validation-errors :errors="validation_errors.opt_prefecture"
                                     v-if="validation_errors.opt_prefecture"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">市区町村</div>
                  <input type="text" v-model="new_user.opt_address1"
                         @input="new_user.opt_address1 = replaceHalfToFull(new_user.opt_address1)"
                         v-bind:class="{error: validation_errors.opt_address1}" name="opt_address1"
                         placeholder="例：新宿区">
                  <validation-errors :errors="validation_errors.opt_address1"
                                     v-if="validation_errors.opt_address1"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">地名・番地</div>
                  <input type="text" v-model="new_user.opt_address2"
                         @input="new_user.opt_address2 = replaceHalfToFull(new_user.opt_address2)"
                         v-bind:class="{error: validation_errors.opt_address2}" placeholder="例：３丁目１３番地">
                  <validation-errors :errors="validation_errors.opt_address2"
                                     v-if="validation_errors.opt_address2"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">建物名・部屋番号<span></span></div>
                  <input type="text" v-model="new_user.opt_address3"
                         @input="new_user.opt_address3 = replaceHalfToFull(new_user.opt_address3)"
                         v-bind:class="{error: validation_errors.opt_address3}" placeholder="例：複眼ビル１１１号室">
                  <validation-errors :errors="validation_errors.opt_address3"
                                     v-if="validation_errors.opt_address3"></validation-errors>
                </div>

              </div>
            </div>

            <p class="alnC mb20">
              すべての項目を確認の上でこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="次に進む">
            </div>
          </div>

          <div id="matrix" class="cts_box anchor_point">
            <h3 class="min_ttl mt50">クラスの詳細について</h3>

            <div class="matrix flex">

              <div class="matrix_col pc">
                <div class="matrix_cell class_select">
                </div>
                <div class="matrix_cell flex gaiyo-title">
                  <h4>概要</h4>
                </div>
                <div class="matrix_cell flex">
                  <h4>月例会</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>懇親会</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>各ワークショップ</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>録画講義</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>複眼IRミーティング</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>コンテスト</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>合宿・ツアー</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>東洋経済会社四季報ONLINE</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>プラス限定講義</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell flex">
                  <h4>プラス限定イベント</h4>
                  <div class="info"><span>詳細を見る</span>
                    <div class="info_box">
                      <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                      <div class="colse_btn"></div>
                    </div>
                  </div>
                </div>
                <div class="matrix_cell class_select">
                </div>
              </div><!--//matrix_col pc-->

              <!--予科-->
              <div class="matrix_col">
                <div class="matrix_cell class_select">
                  <div class="inner">
                    <h3>予科</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">4,950<span>円</span></div>
                      <div class="left pc">&nbsp;</div>
                      <div class="right pc">&nbsp;</div>
                      <span class="month_price pc">&nbsp;</span>
                    </div>
                    <p class="caution">消費税込</p>
                    <a href="javascript:void(0);" v-on:click="rankSelect('rank_preparatory_month')" class="btn" v-if="false">入塾する</a>
                    <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                  </div>
                </div>
                <div class="sp_open_cts">
                  <div class="matrix_cell flex">
                    <div class="gaiyo-text">初級の方、これから投資を始める方など、基礎を学びたい方の講義に特化したコース。基礎コースのため、複眼経済塾の主軸講義である「月例会」等はご覧いただけません。まずは初歩を学んでから本科に進みたい方にお薦め。
                    </div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>月例会</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>懇親会</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="×"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>各ワークショップ</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>別途料金</span></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>録画講義</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>一部のみ</span></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>複眼IRミーティング</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>コンテスト</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>合宿・ツアー</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>東洋経済会社四季報ONLINE</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>プラス限定講義</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>プラス限定イベント</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                  </div>
                  <div class="matrix_cell class_select pc">
                    <div class="inner">
                      <h3>予科</h3>
                      <div class="price flex">
                        <div class="left">月額払い</div>
                        <div class="right">4,950<span>円</span></div>
                        <div class="left">&nbsp;</div>
                        <div class="right">&nbsp;</div>
                        <span class="month_price">&nbsp;</span>
                      </div>
                      <p class="caution">消費税込</p>
                      <a href="javascript:void(0);" v-on:click="rankSelect('rank_preparatory_month')"
                         class="btn" v-if="false">入塾する</a>
                    </div>
                  </div>
                </div>
              </div><!--//matrix_col 予科-->

              <!--本科-->
              <div class="matrix_col">
                <div class="matrix_cell class_select">
                  <div class="inner">
                    <h3>本科</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">16,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">162,000<span>円</span></div>
                      <span class="month_price">（月額換算：13,500円）</span>
                    </div>
                    <p class="caution">消費税込</p>
                    <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_year')" class="btn" v-if="false">入塾する</a>
                    <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                  </div>
                </div>
                <div class="sp_open_cts">
                  <div class="matrix_cell flex">
                    <div class="gaiyo-text">複眼の主軸コース。塾生の８割がこのコースに在籍。最重要講義である「月例会」や「複眼ポートフォリオレビュー」など、投資を実践するための講義が満載。合宿、ツアーや懇親会も本科より参加いただけます。</div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>月例会</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>懇親会</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>各ワークショップ</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>別途料金</span></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>録画講義</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>複眼IRミーティング</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>コンテスト</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>合宿・ツアー</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>東洋経済会社四季報ONLINE</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"><span>追加料金</span></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>プラス限定講義</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>プラス限定イベント</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_cross.svg" alt="✕"></div>
                  </div>
                  <div class="matrix_cell class_select pc">
                    <div class="inner">
                      <h3>本科</h3>
                      <div class="price flex">
                        <div class="left">月額払い</div>
                        <div class="right">16,500<span>円</span></div>
                        <div class="left">年額払い</div>
                        <div class="right">162,000<span>円</span></div>
                        <span class="month_price">（月額換算：13,500円）</span>
                      </div>
                      <p class="caution">消費税込</p>
                      <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_year')" class="btn" v-if="false">入塾する</a>
                    </div>
                  </div>
                </div>
              </div><!--//matrix_col 本科-->

              <!--本科プラス-->
              <div class="matrix_col">
                <div class="matrix_cell class_select">
                  <div class="inner">
                    <h3>本科プラス</h3>
                    <div class="price flex">
                      <div class="left">月額払い</div>
                      <div class="right">27,500<span>円</span></div>
                      <div class="left">年額払い</div>
                      <div class="right">264,000<span>円</span></div>
                      <span class="month_price">（月額換算：22,000円）</span>
                    </div>
                    <p class="caution">消費税込</p>
                    <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_plus_year')" class="btn" v-if="false">入塾する</a>
                    <div v-if="false" class="sp tggle_btn">クラスに含まれる内容を確認する</div>
                  </div>
                </div>
                <div class="sp_open_cts">
                  <div class="matrix_cell flex">
                    <div class="gaiyo-text">最上級コース。全てのワークショップが追加料金無しで受け放題。全イベント優先申込。プラス限定イベントと講義に、四季報ONLINEプレミアムが付いて、このお値段はお得！最もディープに複眼を使いこなしたいなら本科プラス。</div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>月例会</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>月１回開催される主軸講義。四季報分析、日経分析、コンテストレビュー、の３種類が全国各地で開催されます。またこの模様は中継で配信されます。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>懇親会</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>月1回全国各地で開催される月例会に合わせて懇親会を開催。毎回40-50名が参加します。複眼経済塾の最大の特徴は、投資を通じた友人ができること。孤独に投資するのではなく、仲間同士で意見交換することで、より安定した運用が可能になることでしょう。また、毎回塾長・事務局長始め、スタッフが必ず参加して、懇親会でしか話せない、あんなことこんなことを直接お話する機会を設けています。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>各ワークショップ</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>会社四季報の読み方・使い方、そして日経新聞の読み方・使い方を各々半日かけてミッチリ教えます！ワークショップは基本的に都度料金がかかりますが、複数回受講される方が多いため、本科プラスコースでは月謝に料金を含めてしまい毎回無料で受講できるようにお得な設定になっています。また、本科・予科の皆さんも割引料金設定。例えば、<a href="https://www.millioneyes.jp/workshop_shikiho" target="_blank">四季報10倍ワーク（教室版）</a>や<a href="https://www.millioneyes.jp/workshop_nikkei" target="_blank">日経マジ読みワーク</a>は通常5万円のところ本科1万5千円、予科2万3千円の設定です。<a href="https://www.millioneyes.jp/faq/#anser-78" target="_blank">料金表詳細へ</a></p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>録画講義</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>初級から上級まで、毎週様々な講義が配信されています。自慢の一流講義陣による講義は、毎週や月1回、あるいはアットランダムな配信等様々。よく塾生から「全部見る時間がない」との声を頂きますが、全部見る必要はありません。塾としてはすべてのレベルの方々をカバーできるように講義を多数そろえていますが、皆さんはご自身のレベルに合ったものだけをピックアップしてご覧いただければ、十分元は取れる内容だと自負しています。</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>複眼IRミーティング</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>複眼経済塾だからこそできる！上場企業社長とマン・ツー・マン・インタビューによる<a href="https://www.millioneyes.jp/irmtg/" target="_blank">IRミーティング</a>。珠玉の銘柄を発掘しよう！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>コンテスト</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>四季報から「渾身の1社」を選んでエントリー！予科から本科プラスまで全員無料参加可能！四季報発売月の月末にエントリーできます！3カ月間と1年間の騰落を競い、1位は10万円相当、2位5万円相当、3位3万円相当のギフト券をプレゼント！！！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="◯"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>合宿・ツアー</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>複眼合宿は年１回、複眼ツアーも年１回開催。合宿は泊まり込みの勉強会です。ツアーは、大人の修学旅行。全国各地にある普段訪れることがない日本の産業の原点を探る、真にディープで本質に迫るツアー。歴史オタクでもある渡部塾長だからこそ組める行程です。この内容は、日経CNBCで「<a href="https://www.youtube.com/watch?v=_Tmu_G2izs4&amp;list=PLq4TfFKk7jJI0cjEpQyOgfQ1aqgPx6jIq" target="_blank">複眼流投資家道中膝栗毛</a>」というレギュラー番組の土台にもなりました！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>東洋経済会社四季報ONLINE</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>東洋経済新報社と当社のタイアップ。複眼経済塾生だけの特別なプログラムを展開中！本科プラスは、無料で四季報オンラインプレミアム（年5万8千円）が使い放題！また、本科でも「年払いコース」の方は、四季報オンラインプレミアムが<a href="https://www.millioneyes.jp/shikiho_online/" target="_blank">特別割引価格</a>で使えます（通常年5万8千円のところ5万円に！）</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>プラス限定講義</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>本科プラスコースの方だけご覧いただける特別講義を用意しています！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell flex">
                    <div class="left sp">
                      <h4>プラス限定イベント</h4>
                      <div class="info"><span>詳細を見る</span>
                        <div class="info_box">
                          <p>本科プラスの方のみ参加いただける少人数・濃密版のイベントを用意しています。塾長や事務局長等スタッフと一緒に、大人の遠足に行く「前夜祭」など、より深く、より近く複眼経済塾を体感したい方は、ぜひ本科プラス限定イベントにお越しください！</p>
                          <div class="colse_btn"></div>
                        </div>
                      </div>
                    </div>
                    <div class="mark"><img src="/common/images/mark_circle.svg" alt="○"></div>
                  </div>
                  <div class="matrix_cell class_select pc">
                    <div class="inner">
                      <h3>本科プラス</h3>
                      <div class="price flex">
                        <div class="left">月額払い</div>
                        <div class="right">27,500<span>円</span></div>
                        <div class="left">年額払い</div>
                        <div class="right">264,000<span>円</span></div>
                        <span class="month_price">（月額換算：22,000円）</span>
                      </div>
                      <p class="caution">消費税込</p>
                      <a href="javascript:void(0);" v-on:click="rankSelect('rank_regular_plus_year')"
                         class="btn" v-if="false">入塾する</a>
                    </div>
                  </div>
                </div>
              </div><!--//matrix_col 本科プラス-->

            </div>

            <!--//matrix-->
            <p class="alnR mt10"><a href="#class_change">クラスを変更する</a></p>

            <p class="alnC mb20 mt50">
              すべての項目を確認の上でこちらのボタンを押してください。
            </p>
            <div class="button_wrap">
              <input type="submit" class="submit light arrow" value="次に進む">
            </div>
          </div>

        </form>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'
import FormErrorBox from '@/elements/FormErrorBox.vue'
import PrefectureSelect from "@/components/PrefectureSelect";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
    FormErrorBox
  },
  data() {
    if (!this.$store.state.user) {
      this.$router.push({
        name: 'UsersRegistration',
        params: this.$route.params
      })
      return
    }
    let _new_user = {}
    for (const [key, value] of Object.entries(this.$store.state.user)) {
      _new_user[key] = value
    }
    return {
      request_exec: false,
      new_user: this.$store.state.user,
      validation_errors: {},
      errors: null,
      password_type: false,
      password_confirmation_type: false,
      show_rank: false,
      rank_name: null,
      rank_payment: null,
      no_option: this.$store.state.user.rank !== 'rank_regular_year',
      show_shikihou: (this.$store.state.user.rank !== 'rank_preparatory_month' && this.$store.state.user.rank !== 'rank_regular_plus_year' && this.$store.state.user.rank !== 'rank_regular_plus_month'),
      show_shikiho_account: this.$store.state.user.shikiho_account !== null
    }
  },

  mounted() {
    this.updateRankGuide()
    window.$('.acc_menu .acc_cts').hide()
    window.$('.top_cts.acc_menu .acc_cts').show()
    window.$('.acc_menu .acc_tab').on('click', function () {
      window.$(this).next('.acc_cts').slideToggle()
      window.$(this).toggleClass('open')
    });

    // for jquery.
    window.$(".matrix .info span").on("click", function () {
      if (window.$(this).hasClass("open")) {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
        window.$(this).removeClass("open");
      } else {
        window.$(".matrix_cell .info span").removeClass("open");
        window.$(".matrix_cell .info .info_box").hide();
        window.$(this).closest(".matrix_cell").find(".info_box").fadeIn(300);
        window.$(this).addClass("open");
      }
    });
    window.$(".matrix .info_box .colse_btn,.matrix .info_box a").on("click", function () {
      window.$(this).closest(".matrix_cell").find(".info span").removeClass("open");
      window.$(this).closest(".matrix_cell").find(".info_box").fadeOut(300);
    })
    window.$(".matrix .tggle_btn").on("click", function () {
      window.$(this).closest(".matrix_col");
      window.$(this).closest(".matrix_col").find(".sp_open_cts").slideToggle();
      window.$(this).closest(".matrix_col").toggleClass("open");
    });
  },
  methods: {
    changeShikihoAccount() {
      if (!this.show_shikiho_account) {
        this.new_user.shikiho_account = null
      }
    },
    showShikihoAccount() {
      const shikiho = this.$store.state.user.shikiho
      const rank = this.$store.state.user.rank
      // console.log(rank, shikiho)
      return rank === 'rank_regular_plus_year' ||
          rank === 'rank_regular_plus_month' ||
          shikiho === 'rank_regular_shikiho_premium_year' ||
          shikiho === 'rank_regular_shikiho_basic_year'
    },
    rankChange() {
      setTimeout(() => {
        this.new_user.shikiho = ''
        if (this.new_user.rank === 'rank_regular_year' || this.new_user.rank === 'rank_regular_month') {
          setTimeout(() => {
            window.$('#course04').prop('checked', true)
          }, 10)
        } else {
          setTimeout(() => {
            window.$('#course04').prop('checked', false)
          }, 10)
        }
        this.no_option = this.new_user.rank !== 'rank_regular_year'
        this.show_shikihou = (this.new_user.rank !== 'rank_preparatory_month' && this.new_user.rank !== 'rank_regular_plus_year' && this.new_user.rank !== 'rank_regular_plus_month')
        this.updateRankGuide()
        this.$forceUpdate();
      }, 100)
    },

    // オプション変更
    shikihoChange() {
      console.log(this.new_user.shikiho);
    },

    prevClicked() {
      this.$router.push({
        name: 'UsersConfirmationPage3',
        params: this.$route.params
      })
    },
    nextClicked(event) {
      if (this.request_exec) return
      this.request_exec = true

      event.preventDefault()
      this.validation_errors = {}
      this.errors = null

      const rank_bk = this.new_user.rank
      const shikiho_bk = this.new_user.shikiho
      if (shikiho_bk && shikiho_bk !== 'none') {
        this.new_user.rank = shikiho_bk
      }

      if (this.new_user.shikiho_account && this.new_user.shikiho_account.match('@toyokeizai.net')) {
        this.new_user.shikiho_account = this.new_user.shikiho_account.split('@')[0]
      }

      this.new_user.password_validates = true

      this.startUpload()
      this.axios
          .post(`${this.env.api_base_url}users/create.json`, {
            user: this.new_user,
            campaign_code: this.new_user.campaign_code
          })
          .then(response => {
            this.$router.push({
              name: 'UsersConfirmationFinish',
              params: {token: response.data.token}
            })
          })
          .catch((error) => {
            if (error.response.data.validation_errors) {
              this.new_user.rank = rank_bk
              this.new_user.shikiho = shikiho_bk

              this.scrollTop()
              this.errors = true
              this.validation_errors = error.response.data.validation_errors
            }
            if (error.response.data && error.response.data.error) {
              // TODO: エラー処理
              // this.errors = [error.response.data.error]
            }
            this.$forceUpdate();
          })
          .finally(() => {
            this.finishUpload()
            this.request_exec = false
          })
    },
    prefectureChanged(prefecture) {
      this.new_user.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.new_user.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.new_user.zip) {
        this.addressSearch(this.new_user.zip)
            .then(data => {
              this.new_user.prefecture = data.pref
              this.new_user.address1 = data.address
              this.$forceUpdate()
            })
      }
    },
    enablePrimaryAddressClicked() {
      if (this.new_user.enable_primary_address) {
        this.new_user.opt_zip = this.new_user.zip
        this.new_user.opt_prefecture = this.new_user.prefecture
        this.new_user.opt_address1 = this.new_user.address1
        this.new_user.opt_address2 = this.new_user.address2
        this.new_user.opt_address3 = this.new_user.address3
        this.$forceUpdate()
      }
    },
    optZipSearch() {
      if (this.new_user.opt_zip) {
        this.addressSearch(this.new_user.opt_zip).then(data => {
          this.new_user.opt_prefecture = data.pref
          this.new_user.opt_address1 = data.address
          this.$forceUpdate()
        })
      }
    },
    updateRankGuide() {
      this.rank_name = {
        rank_regular_plus_month: '本科プラス',
        rank_regular_plus_year: '本科プラス',
        rank_regular_month: '本科',
        rank_regular_year: '本科',
        rank_regular_shikiho_premium_year: '本科',
        rank_regular_shikiho_basic_year: '本科',
        rank_preparatory_month: '予科',
      }[this.new_user.rank]

      this.rank_payment = {
        rank_regular_plus_month: '月額払い',
        rank_regular_plus_year: '年額払い',
        rank_regular_month: '月額払い',
        rank_regular_year: '年額払い',
        rank_regular_shikiho_premium_year: '年額払い',
        rank_regular_shikiho_basic_year: '年額払い',
        rank_preparatory_month: '月額払い',
      }[this.new_user.rank]
    }
  }
}
</script>
